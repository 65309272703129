@import '../../styles/colors.scss';

.address-fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;

}
.address-fields-inline {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 10px 0;
}

.address-field-state {
  flex-grow: 1;
}

.errorMessage {
  color: #DC1738;
  font-size: 14px;
  line-height: 22px;
  padding-left: 16px;
}

@media only screen and (min-width: 768px) {
  .address-fields-inline {
    display: inline-flex;
    flex-direction: row;
    flex: 0 0 49%;
  }
  .forceInline {
    width: 49%;
  }
}

//making it global to apply styles to google auto complete html container
:global .display-none {
  display: none;
}
