@import './colors.scss';
@import './normalize.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
}

h2 {
  font-size: 28px;
  font-stretch: normal;
  color: $japaneseIndigo;
}

h4 {
  font-size: 16px;
  font-stretch: normal;
  color: $japaneseIndigo;
  margin: 24px 0 0;
}

p {
  font-size: 16px;
  letter-spacing: 0;
  font-stretch: normal;
  color: $slate;
  line-height: 22px;
}

a {
  text-decoration: none;
}

// Form Styles
label {
  color: $bluishGrey;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
}

input {
  padding: 16px 0 8px 0;
  color: $japaneseIndigo;
}

:global(.loading-spin) {
  display: block;
  margin: 0 auto;
  width: 500px;
}

:global(.yog-app) {
  //width: 100%;
  max-width: 1000px;
  margin: 0 auto;

}

:global(#root) {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

:global(.modal-active) {
  overflow: hidden;
}
