@import '../../styles/colors.scss';

.button {
    display: block;
    text-align: center;
    border: 0;
    background-color: $sunsetOrange;       // color from main
    color: white;
    width: 304px;
    max-width: 304px;
    height: 50px;
    border-radius: 6px;
    font-size: 19px;
    outline:0;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
    &:active {
        transform: translateY(2px);
        box-shadow: none;
    }
}

.button[buttonStyle='transparent']{
    border: 1px solid $sunsetOrange;
    background-color:transparent;       // color from main
    color: $sunsetOrange;
}

.button[buttonStyle='transparentBorder']{
    border: none;
    background-color:transparent;       // color from main
    color: $sunsetOrange;
    &:hover {
        box-shadow: none;
    }
}

.button[buttonStyle='yahoo']{
    border: 1px solid $yahoo;
    background-color: $yahoo;
    color: $white;
}

.button[buttonStyle='outlook']{
    border: 1px solid $outlook;
    background-color: $outlook;
    color: $white;
}

.button[buttonStyle='phone']{
    border: 1px solid $orangeStatus;
    background-color: $orangeStatus;
    color: $white;
}

.button[disabled]{
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
        box-shadow: none;
    }
}
.customStyles {
    background-color: #4193AC;       // color from main
    color: white;
}

.customStyles2 {
    background-color: #3276ce;       // color from main
    color: white;
}
.customStyles3 {
    width: 100%;
    background-color: #dedbfc;       // color from main
    color: white;
}

.customStyles4 {
    max-width: 103px;
    background-color: #96d9d6;       // color from main
    color: white;
}
