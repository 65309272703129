@import '../../styles/colors.scss';


.formContainer {
  width: 100%;
}

.formfieldsContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  grid-gap: 0px;
  grid-template-columns: 1fr;
  padding: 0 20px;
}

.formField {
  padding: 6px 0;
  width: 100%;
  flex: 1 0 100%;
}

@media screen and (max-width: 767px) {
  .formFieldInline {
    display: flex;
    width: 100%;
  }

  .formFieldInlineIndent {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .formContainer {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
  }

  .formfieldsContainer {
    grid-gap: 10px;
  }
  
  .formFieldInline {
    display: inline-flex;
    flex-direction: row;
    flex: 1 0 49%;
  }
}

.textFieldClass {
  color: #645756;
  &:focus {
    color: #645756;
    border-bottom: 1px solid #d4c4c3;
  }
  &::placeholder {
    color: #c5b1af;
  }
}

.submitButtonStyle {
  width: 100px;
  height: 50px;
  padding: 0;
  margin: 16px;
  background-color: $sunsetOrange;
  color: $white;
  border-radius: 25px;
  border: 0;
  font-size: 16px;

  &:hover {
    background-color: $darkSunsetOrange;
  }

  &[disabled] {
    background-color: $paleBlue;
  }
}

.outlineButton {
  color: $sunsetOrange;
  background-color: transparent;

  &[disabled] {
    color: $paleBlue;
    background-color: $white;
  }
}

.formFooterContainer {
  flex: 1;
	background-color: $paleGray;
  width: 100%;
  max-width: 100%;
  border-top: 1px solid $lightSkyBlue;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
