.thankYou {
  padding: 20px;
  text-align: center;
  font-size: 16px;
  text-align: justify;
}

@media only screen and (min-width: 768px) {
  .thankYou {
    width: 100%;
    padding: 2em 1em;
  }
}

@media only screen and (min-width: 1280px) {
  .thankYou {
    width: 700px;
    margin: 0 auto;
  }
}
