@import '../../styles/colors.scss';

.label {
  color: #887373;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.textArea {
  color: $paleBlue;
  font-size: 18px;
  line-height: 22px;
  outline: 0;
  border: 1px solid $grey60;
  width: 100%;
  height: 100px;
  margin: 0;
  padding: 8px;

  &:focus {
    color: $licorice;
    border-bottom: 1px solid $licorice;
  }

  &::placeholder {
    color: $paleBlue;
  }

}

.textArea-filled {
  color: $licorice;
}

.textAreaError {
  color: $paleRed;
  font-size: 14px;
  line-height: 22px;
  padding-top: 8px;
}

.textAreaAlert {
  margin-left: -18px;
  margin-bottom: -3px;
  height: 18px;
  width: 18px;
}
