.header {
	display: block;
	margin: 0 auto;
	padding: 8px 16px;
	width: 100%;
  background-color: white;
}

.toran {
	background-image: url('../../assets/images/toran.png');
	background-repeat: repeat-x;
	background-size: auto 5vw;
	height: 120px;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;

	@media only screen and (min-width: 768px) {
		background-size: auto 4vw;
	}
}

.logo_container {
  margin: 0 auto;
  max-width: 1024px;
  img {
    height: 50px;
  }
}

.logo_link {
	display: block;
  width: fit-content;
}
