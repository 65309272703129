@import '../../styles/colors.scss';

.screen-title {
    .title {
        color: #7f3838;
        font-size: 18px;
        text-transform: capitalize;
    }

    .description {
        color: $slate;
        font-size: 16px;
        margin: 16px 0px;
    }
}
