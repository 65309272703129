.app-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: calc(10px + 2vmin);
	color: #000;
}

.main {
	background-color: #f5e3e2;
	background-size: auto 4%;
	background-repeat: repeat;
	background-image: url('./assets/images/bg-pattern@2x.png');
	background-blend-mode: color-burn;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.hero-img-tx {
	width: 100%;
	max-width: 920px;
	display: block;
	margin: 2.6em auto 0px;

	@media only screen and (min-width: 1000px) {
		margin: 5em auto 12px;
		border-radius: 24px;
	}
}

.hero-img {
	width: 100%;
	max-width: 1024px;
	display: block;
	margin: 2em auto 8px;

	@media only screen and (min-width: 1000px) {
		border-radius: 24px;
	}
}
