$algaeGreen: #26c972;
$bluishGrey: #7f8f9d;
$cloudy: #ebf0f5;
$columbiaGrey: #c3d2df;


$grey: #D8D8D8;
$grey1: #e6e6e7;
$grey2: #cdcdcf;
$grey3: #87898D;
$grey4: #494D52;
$grey60: #848EAC;
$grey80: #3A4463;
$ghostWhite: #f9fafb;
$japaneseIndigo: #2a3d4c;
$licorice: #0E151A;
$moonstoneGrey: #5c85a7;
$oceanBlue: #067bc2;
$paleBlue: #dde6ef;
$paleRed: #d63230;
$raspberry: #ad3962;
$sunglowYellow: #ffca3a;
$sunsetOrange: #ff5a52;
$darkSunsetOrange: #E8524B;
$UFOGreen: #47d489;
$winterGreen: #2ec4b6;
$white: #ffffff;
$storm: #4e5d6a;
$slate: #535D6A;
$paleGray: #f9fafb;

$red: #FD4441;
$grey: #D8D8D8;
$lightSkyBlue: #dee2e5;
$yahoo: #410093;
$outlook: #3176ce;
$orangeStatus : #E69D1E;





