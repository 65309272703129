@import '../../styles/colors.scss';

.select {
    display: block;
    width: 100%;

    select {
        appearance: none;
        color: #645756;
        font-size: 18px;
        line-height: 22px;
        border: 1px solid #d4c4c3;
        padding: 8px;
        padding-right: 36px;
        border-radius: 6px;
        height: 40px;
        width: 100%;
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 0px;
        text-overflow: ellipsis;
    }
}

.selectFieldWrapper {
    position: relative;
}

.caretDownIcon {
    position: absolute;
    right: 18px;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
}

.textFieldError {
    color: $paleRed;
    font-size: 14px;
    line-height: 22px;
    padding-top: 8px;
}

.textFieldAlert {
    margin-left: -18px;
    margin-bottom: -3px;
    height: 18px;
    width: 18px;
} 

.label {
    color: #6B433F;
    display: block;
    font-size: 16px;
    line-height: 22px;
}
