@import '../../styles/colors.scss';

.label {
  color: #6B433F;
  display: block;
  font-size: 16px;
  line-height: 22px;
}

.textField {
  border-radius: 6px;
  color: #645756;
  font-size: 18px;
  line-height: 22px;
  outline: 0;
  border: 1px solid #D4C4C3;
  padding: 8px;
  height: 40px;
  width: 100%;
  margin: 0;

  &::placeholder {
    color: $paleBlue;
  }
}

.textFieldError {
  color: $paleRed;
  font-size: 14px;
  line-height: 22px;
  padding-top: 8px;
}

.textFieldAlert {
  margin-left: -28px;
  margin-bottom: -3px;
  height: 18px;
  width: 18px;
}
