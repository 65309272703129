@import '../../styles/colors';

.make-payment-screen {
  width: 100%;
  padding: 0 20px;
}

.cc-control {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .cc-control {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .cc-control {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}

.cc-control > div {
  display: inline-block;
}

.title {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  margin-bottom: 40px;
  color: $japaneseIndigo;
}

.separator {
  position: relative;
  font-size: 1.8em;
  line-height: 1;
  font-weight: 300;
  margin: 32px auto;
  color: $grey80;

  & span:before,
  & span:after {
    content: '';
    height: 1px;
    width: 40%;
    display: inline-block;
    margin: 0 1%;
    background-color: $japaneseIndigo;
    position: relative;
    top: -6px;
  }
}

.payment-amount {
  margin: 1rem 0;
  color: $slate;
  font-size: 18px;

  .amount {
    font-weight: 500;
    text-decoration: underline;
  }
}

.label {
  font-family: 'Rubik', sans-serif;
  color: #6B433F;
  display: block;
  font-size: 16px;
  line-height: 22px;
}

:global {
  #ccNumber,
  #ccExpiry,
  #ccCVV,
  #ccPostalCode {
    background-color: white;
    border-radius: 6px;
    border: 1px solid #d4c4c3;
    padding: 10px 8px;
  }
}

.secure-payment {
  height: 2rem;
}

.securePaymentContainer {
  margin: 2rem 0 2rem 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

.securePaymentText {
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  color: #00ad2e;
  margin-left: 1rem;
}

.error-box {
  margin-top: 20px;
  grid-column: 1 / span 2;
  border: 1px solid #d63230;
  background-color: #ffeae7;
  padding: 20px;
  border-radius: 4px;
  font-size: 14px;
}

.recaptcha {
  margin-top: 42px;
  display: flex;
  justify-content: center;
}

.legalDisclaimer {
  margin: 6rem 0 1rem 0;
  font-weight: 500;
  color: #3a4463;
  display: block;
  font-size: 16px;
  line-height: 22px;
}
